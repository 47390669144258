import React from 'react';
import { Col, Row } from 'emotion-flex';
import Container from '../Homepage/Container';
import { SYSTEM_FONTS } from '../../styles/typography';
import presets from '../../styles/presets';
import { ReactComponent as LeftArrow } from '../../assets/images/programs-slider-left-arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/images/program-slider-right-arrow.svg';
import bgImage from '../../assets/images/employers-testimonial-bg.svg';
import { ContentfulContentBlock } from '../../graphql-types';
import GatsbyImage from 'gatsby-image';
import { Slider } from '..';
import { css } from '@emotion/core';
import { MonoFontLabel } from '../common/typography';

function SliderArrow({
  onClick,
  position,
}: {
  position: string;
  onClick?: () => void;
}) {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        [position]: -120,
      }}
      onClick={onClick}
    >
      {position === 'left' ? <LeftArrow /> : <RightArrow />}
    </div>
  );
}

const SliderWithHeading = ({
  sliderData,
}: {
  sliderData: ContentfulContentBlock;
}) => {
  const teamSlidersettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: '0px',
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SliderArrow position="left" />,
    prevArrow: <SliderArrow position="right" />,
    responsive: [
      {
        breakpoint: 1760,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section
      css={css`
        background-image: url(${bgImage});
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 1250px;

        margin-top: -250px;
        padding-top: 350px;

        position: relative;

        ${presets.md} {
          min-height: 900px;
        }

        *:focus {
          outline: 0;
        }
      `}
    >
      <Container
        css={{
          maxWidth: 1050,
          margin: '0 auto',
        }}
      >
        <div css={{ textAlign: 'center' }}>
          {sliderData.prefix && (
            <MonoFontLabel>{sliderData.prefix}</MonoFontLabel>
          )}
          <h4
            css={{
              fontFamily: ['Blacker Pro Display']
                .concat(SYSTEM_FONTS)
                .join(', '),
              fontSize: 28,
              color: '#160B2C',
              marginBottom: 46,
              [presets.md]: {
                marginBottom: 0,
                fontSize: 40,
              },
            }}
            dangerouslySetInnerHTML={{ __html: sliderData.title }}
          />
        </div>
        <Slider {...teamSlidersettings}>
          {sliderData.itemsLongText.map((item) => {
            return (
              <div key={item.id}>
                <Row
                  css={{
                    minHeight: 580,
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    padding: '0 20px',

                    [presets.sm]: {
                      flexDirection: 'row',
                    },
                  }}
                >
                  {/* 1 */}
                  <Col
                    xs={12}
                    md={6}
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <p
                      css={{
                        fontFamily: ['Blacker Pro Display']
                          .concat(SYSTEM_FONTS)
                          .join(', '),
                        fontSize: 20,
                        lineHeight: '40px',
                        color: '#160B2C',
                        marginTop: 0,
                        marginBottom: 0,

                        [presets.sm]: {
                          fontSize: 30,
                        },
                      }}
                    >
                      {item.text.childMarkdownRemark.rawMarkdownBody}
                    </p>
                    <p
                      css={{
                        fontFamily: ['Source Code Pro']
                          .concat(SYSTEM_FONTS)
                          .join(', '),
                        fontSize: 14,
                        color: '#489F9D',
                        marginTop: 28,
                        marginBottom: 0,
                      }}
                    >
                      {item.prefix}
                    </p>
                  </Col>
                  {/* 2 */}
                  <Col
                    xs={12}
                    mdOffset={1}
                    md={5}
                    css={{
                      marginBottom: 51,
                      [presets.sm]: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 51,
                      },
                    }}
                  >
                    <GatsbyImage
                      fluid={item.image.fluid}
                      alt={item.prefix}
                      css={{ width: '100%' }}
                    />
                  </Col>
                </Row>
              </div>
            );
          })}
        </Slider>
        {/* Button */}
      </Container>
    </section>
  );
};

export default SliderWithHeading;
