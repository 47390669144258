/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from 'react';
import ReactSlider from 'react-slider';
import styled from '@emotion/styled';
import hexToRgba from 'hex-to-rgba';

interface StyledTrackProps {
  index: number;
}

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 6px;
  margin: 0 auto;
`;

const StyledThumb = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  margin-top: -9px;
  background-color: #d2eedd;
  cursor: grab;

  &:focus {
    outline: 0;
  }
`;

const Thumb = (props: any) => <StyledThumb {...props} />;

const StyledTrack = styled.div<StyledTrackProps>`
  top: 0;
  bottom: 0;
  background: ${({ index }) =>
    index === 1 ? hexToRgba('#160B2C', 0.2) : '#d2eedd'};
  border-radius: 999px;
`;

const Track = (props: any, state: any) => {
  return <StyledTrack {...props} index={state.index} />;
};

const CalculatorSlider = ({
  maximumEmployeesNumber,
  defaultEmployeesNumber,
  setValue,
}: {
  maximumEmployeesNumber: number;
  defaultEmployeesNumber: number;
  setValue: Dispatch<SetStateAction<number>>;
}) => {
  return (
    <div css={{ maxWidth: 820, margin: '0 auto' }}>
      <StyledSlider
        min={1}
        max={maximumEmployeesNumber}
        defaultValue={defaultEmployeesNumber}
        renderTrack={Track}
        renderThumb={Thumb}
        onChange={(val: any) => setValue(val)}
      />
    </div>
  );
};

export default CalculatorSlider;
