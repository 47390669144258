import React from 'react';
import presets from '../../styles/presets';
import { SYSTEM_FONTS } from '../../styles/typography';
import { css } from '@emotion/core';
import hexToRgba from 'hex-to-rgba';
import { ContentfulImageHeadingAndShortText } from '../../graphql-types';

const HighlightsTopBubble = ({
  prefix,
  heading,
  shortText,
  image,
}: ContentfulImageHeadingAndShortText) => {
  return (
    <div
      css={css`
        position: absolute;
        width: 75%;
        background-color: #fff;
        border-radius: 12.6px;
        display: flex;
        top: 25%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);

        padding: 8px 8px;

        @media (min-width: 355px) {
          top: 23%;
        }
        @media (min-width: 365px) {
          top: 20%;
          width: 70%;
          padding: 12px 12px;
        }
        @media (min-width: 480px) {
          padding: 25px 15px;
        }
        @media (min-width: 767px) {
          top: 20%;
          width: 75%;
          padding: 8px 8px;
        }

        @media (min-width: 1120px) {
          padding: 25px 15px;
        }
      `}
    >
      <img
        src={image.fluid.src}
        alt="person"
        css={css`
          height: 40px;
          width: 40px;
          border-radius: 20px;
          margin-right: 4px;
        `}
      />
      <div>
        <p
          css={css`
            fontfamily: ${['Blacker Pro Display']
              .concat(SYSTEM_FONTS)
              .join(', ')};
            font-size: 14px;
            font-weight: 500;
            color: #160b2c;
            margin-bottom: 0;

            @media (min-width: 1120px) {
              font-size: 16px;
            }
          `}
        >
          {prefix}
        </p>
        <p
          css={{
            fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
            fontSize: 10,
            color: '#1FABAE',
            marginBottom: 2,

            [presets.xl]: {
              marginBottom: 4,
            },
          }}
        >
          {heading}
        </p>
        <p
          css={css`
            font-family: ${['Open Sans'].concat(SYSTEM_FONTS).join(', ')};
            font-size: 8px;
            color: ${hexToRgba('#160B2C', 0.8)};
            margin-bottom: 0;

            @media (min-width: 414px) {
              font-size: 9px;
            }
            @media (min-width: 450px) {
              font-size: 10px;
            }
            @media (min-width: 500px) {
              font-size: 12.5px;
            }

            @media (min-width: 767px) {
              font-size: 9px;
            }

            @media (min-width: 900px) {
              font-size: 10px;
            }

            @media (min-width: 960px) {
              font-size: 12.5px;
            }
          `}
        >
          {shortText}
        </p>
      </div>
    </div>
  );
};

export default HighlightsTopBubble;
