import React from 'react';
import presets from '../../styles/presets';
import Container from '../Homepage/Container';
import { css } from '@emotion/core';
import { SYSTEM_FONTS } from '../../styles/typography';
import hexToRgba from 'hex-to-rgba';
import Logos from './Logos';
import {
  ContentfulLogoItem,
  ContentfulImageHeadingAndShortText,
  ContentfulText,
} from '../../graphql-types';
import { Row, Col } from 'emotion-flex';
import phoneImg from '../../assets/images/employers-phone.png';
import chartImg from '../../assets/images/employers-chart.png';
import FoundersTick from './FoundersTick';
import HighlightsTopBubble from './HighlightsTopBubble';

interface LogosAndHighlightsProps {
  logos: ContentfulLogoItem[];
  headline: string;
  highlights: ContentfulImageHeadingAndShortText;
  highlightsItems: ContentfulText[];
  highlightsBubble: ContentfulImageHeadingAndShortText;
}

const LogosAndHighlights: React.FC<LogosAndHighlightsProps> = ({
  logos,
  headline,
  highlights,
  highlightsItems,
  highlightsBubble,
}) => {
  return (
    <section>
      <div
        css={css`
          min-height: 900px;

          padding-top: 40px;
          padding-bottom: 240px;

          ${presets.xl} {
            min-height: 1000px;
          }
        `}
      >
        <Container
          css={css`
            margin: 0 auto;
          `}
        >
          <p
            css={{
              fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
              fontSize: 16,
              color: hexToRgba('#160B2C', 0.8),
              marginBottom: 22,
              textAlign: 'center',
            }}
          >
            {headline}
          </p>
          <Logos {...{ logos }} />

          <Row css={{ maxWidth: 1088, margin: '146px auto 0' }}>
            {/* 1 COL */}
            <Col
              xs={12}
              md={6}
              css={{
                paddingRight: 0,
                paddingLeft: 0,
                [presets.md]: { paddingRight: 49 },
              }}
            >
              <div
                css={{
                  backgroundColor: '#FFDAD5',
                  borderRadius: 10,
                  minHeight: 346,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <img
                  src={phoneImg}
                  alt="phone"
                  css={{
                    minHeight: 320,
                    marginBottom: 0,
                    alignSelf: 'flex-end',
                    width: '100%',
                  }}
                />
                <HighlightsTopBubble {...highlightsBubble} />
                <img
                  src={chartImg}
                  alt="chart"
                  css={css`
                    position: absolute;
                    bottom: -20px;
                    max-width: 77%;

                    @media (min-width: 365px) {
                      max-width: 72%;
                    }
                    @media (min-width: 767px) {
                      max-width: 77%;
                    }

                    @media (min-width: 900px) {
                      max-width: 78%;
                    }
                  `}
                />
              </div>
            </Col>
            {/* 2 COL */}
            <Col
              xs={12}
              md={6}
              css={{
                paddingLeft: 0,
                paddingRight: 0,
                [presets.md]: { paddingLeft: 49 },
              }}
            >
              <div
                css={css`
                  max-width: 470px;
                  text-align: center;
                  margin: 0 auto;
                  padding: 0 15px;

                  @media (min-width: 417px) {
                    padding: 0 50px;
                  }

                  @media (min-width: 768px) {
                    text-align: left;
                    margin: 0;
                    padding: 0;
                  }
                `}
              >
                <p
                  css={{
                    fontFamily: ['Blacker Pro Display']
                      .concat(SYSTEM_FONTS)
                      .join(', '),
                    maxWidth: 420,
                    fontSize: 24,
                    lineHeight: '30px',
                    fontWeight: 500,
                    color: '#160B2C',
                    marginTop: 56,
                    marginBottom: 18,

                    [presets.md]: {
                      fontSize: 36,
                      lineHeight: '40px',
                    },
                  }}
                >
                  {highlights.heading}
                </p>
                <p
                  css={{
                    fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
                    fontSize: 14,
                    color: hexToRgba('#160B2C', 0.8),
                    marginTop: 0,
                    marginBottom: 31,
                  }}
                >
                  {highlights.shortText}
                </p>
                {/* TICKS BOX */}
                <div>
                  {highlightsItems.map((item) => {
                    return (
                      <FoundersTick
                        key={item.id}
                        simpleText={item.simpleText}
                      />
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default LogosAndHighlights;
