import React from 'react';
import presets from '../../styles/presets';
import Container from '../Homepage/Container';
import { css } from '@emotion/core';
import { SYSTEM_FONTS } from '../../styles/typography';
import { ContentfulImageHeadingAndLongText } from '../../graphql-types';
import { Row, Col } from 'emotion-flex';
import GatsbyImage from 'gatsby-image';
import bgImage from '../../assets/images/employers-dark-bg.svg';
import { MonoFontLabel } from '../common/typography';

interface ProblemsProps {
  problemsArray: ContentfulImageHeadingAndLongText[];
  problemsNumbersArray: string[];
}

const Problems: React.FC<ProblemsProps> = ({
  problemsArray,
  problemsNumbersArray,
}) => {
  return (
    <section
      css={css`
        background-image: url(${bgImage});
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;

        position: relative;
        z-index: 2;
        min-height: 700px;

        margin-top: -110px;
        padding-top: 110px;
        padding-bottom: 100px;
      `}
    >
      <Container>
        <Row css={{ maxWidth: 1088, margin: '0 auto' }}>
          {problemsArray.map((item, index) => {
            return (
              <Col
                key={item.id}
                xs={12}
                md={6}
                css={{
                  [presets.md]: { padding: '0 30px' },
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  <GatsbyImage
                    fluid={item.image.fluid}
                    css={{
                      width: '70%',
                      // maxWidth: 350,
                      maxHeight: 300,
                      marginBottom: 0,
                      position: index === 1 ? 'absolute' : 'static',
                      bottom: index === 1 && -25,
                      left: index === 1 && -45,
                    }}
                  />
                  <div>
                    <MonoFontLabel>{item.heading}</MonoFontLabel>
                    <p
                      css={{
                        fontFamily: ['Blacker Pro Display']
                          .concat(SYSTEM_FONTS)
                          .join(', '),
                        fontSize: 24,
                        color: '#fff',
                        maxWidth: 450,
                        margin: '0 auto 40px',

                        [presets.lg]: {
                          fontSize: 26,
                        },
                      }}
                    >
                      {item.text.childMarkdownRemark.rawMarkdownBody}
                      <sup
                        css={{
                          fontFamily: ['Open Sans']
                            .concat(SYSTEM_FONTS)
                            .join(', '),
                          marginLeft: 5,
                          fontSize: 12,
                        }}
                      >
                        {problemsNumbersArray[index]}
                      </sup>
                    </p>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default Problems;
