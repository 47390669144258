import React from 'react';
import presets from '../../styles/presets';
import {
  ContentfulContentBlock,
  ContentfulCalculator,
  ContentfulLink,
  ContentfulCalculatorNumbers,
} from '../../graphql-types';
import Container from '../Homepage/Container';
import bgImage from '../../assets/images/employers-calculator-bg.svg';
import { css } from '@emotion/core';
import { SYSTEM_FONTS } from '../../styles/typography';
import hexToRgba from 'hex-to-rgba';
import Button from '../common/Button';
import CalculatorComponent from './CalculatorComponent';
import { MonoFontLabel } from '../common/typography';

interface CalculatorSectionProps {
  calculatorSection: ContentfulContentBlock;
  calculatorData: ContentfulCalculator;
  calculatorNumbers: ContentfulCalculatorNumbers;
  calculatorCta1: ContentfulLink;
  calculatorCta2: ContentfulLink;
  scrollToIntegrationSection: () => void;
  scrollToDemoSection: () => void;
}

const CalculatorSection: React.FC<CalculatorSectionProps> = ({
  calculatorSection,
  calculatorData,
  calculatorNumbers,
  calculatorCta1,
  calculatorCta2,
  scrollToIntegrationSection,
  scrollToDemoSection,
}) => {
  const { prefix, title, text } = calculatorSection;
  return (
    <section>
      <div
        css={css`
          background-image: none;
          background-image: url(${bgImage});
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          min-height: 1100px;
          margin: 0 auto;

          position: relative;
          z-index: 1;

          ${presets.md} {
            min-height: 918px;
          }
        `}
      >
        <Container
          css={css`
            padding-top: 134px;
            margin: 0 auto;
            text-align: center;
            @media (min-width: 1454px) {
              padding-top: 161px;
            }
          `}
        >
          {/* Heading */}
          {prefix && <MonoFontLabel>{prefix}</MonoFontLabel>}
          <h4
            css={{
              fontFamily: ['Blacker Pro Display']
                .concat(SYSTEM_FONTS)
                .join(', '),
              fontSize: 28,
              color: '#160B2C',
              marginBottom: 16,
              [presets.md]: {
                fontSize: 40,
              },
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p
            css={{
              fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
              fontSize: 14,
              color: hexToRgba('#160B2C', 0.7),
              marginBottom: 36,

              [presets.lg]: {
                fontSize: 18,
                color: hexToRgba('#160B2C', 0.8),
              },
            }}
          >
            {text && text.childMarkdownRemark.rawMarkdownBody}
          </p>
          {/* Calculator */}
          <CalculatorComponent {...{ calculatorData, calculatorNumbers }} />
          {/* Buttons */}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin: 0 auto;
              max-width: 70vw;
              max-width: 350px;

              ${presets.md} {
                max-width: 524px;
                flex-direction: row;
                justify-content: space-between;
              }
            `}
          >
            {calculatorCta1 && calculatorCta1.isDisplayed && (
              <Button onClick={scrollToDemoSection}>
                {calculatorCta1.text}
              </Button>
            )}
            {calculatorCta2 && calculatorCta2.isDisplayed && (
              <Button
                onClick={scrollToIntegrationSection}
                css={{
                  background: 'transparent',
                  border: '1px solid #489F9D',
                  marginTop: 16,
                  color: '#489F9D',
                  [presets.md]: { marginTop: 0, padding: '1rem 4rem' },
                }}
              >
                {calculatorCta2.text}
              </Button>
            )}
          </div>
        </Container>
      </div>
    </section>
  );
};

export default CalculatorSection;
