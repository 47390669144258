import React, { useState } from 'react';
import Container from '../Homepage/Container';
import { css } from '@emotion/core';
import { ContentfulContentBlock } from '../../graphql-types';
import presets from '../../styles/presets';
import { SYSTEM_FONTS } from '../../styles/typography';
import { ButtonLink } from '../common/Button';
import hexToRgba from 'hex-to-rgba';
import styled from '@emotion/styled';
import { ItemBoxComp } from '../Programs/LogosAndBenefits';
import ArrowIcon from '../../assets/images/arrow-icon.svg';
import { MonoFontLabel } from '../common/typography';

interface ArrowImageProps {
  isActive: boolean;
}

const StyledHeading = styled.p`
  font-family: ${['Blacker Pro Display'].concat(SYSTEM_FONTS).join(', ')};
  font-size: 20px;
  font-weight: 500;
  color: ${hexToRgba('#160B2C', 0.8)};
  margin-bottom: 0;
`;

const ArrowImage = styled.img<ArrowImageProps>`
  margin-bottom: 0;
  margin-left: 7px;
  transition: 0.25s ease-in-out;
  max-width: 16px;
  transform: ${({ isActive }) => (isActive ? 'rotate(0)' : 'rotate(180deg)')};
  &:hover {
    cursor: pointer;
  }
`;

const Faq: React.FC<ContentfulContentBlock> = ({
  prefix,
  title,
  callToAction,
  itemsLongText,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <section
      css={css`
        background-color: '#fff';

        min-height: ${800 + itemsLongText.length * 50}px;
        padding: 100px 0;

        ${presets.lg} {
          min-height: ${600 + itemsLongText.length * 50}px;
          padding: 150px 24px 0;
        }
      `}
    >
      <Container
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 1260,

          [presets.lg]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          },
        }}
      >
        <div
          css={{
            maxWidth: 300,
            textAlign: 'center',
            [presets.lg]: {
              textAlign: 'left',
            },
          }}
        >
          {prefix && <MonoFontLabel>{prefix}</MonoFontLabel>}
          <h4
            css={{
              fontFamily: ['Blacker Pro Display']
                .concat(SYSTEM_FONTS)
                .join(', '),
              fontSize: 28,
              color: '#160B2C',
              marginBottom: 39,
              [presets.md]: {
                fontSize: 40,
              },
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />

          <div css={{ display: 'none', [presets.lg]: 'block' }}>
            {callToAction && callToAction.isDisplayed && callToAction.url && (
              <ButtonLink to={callToAction.url} css={{ width: '100%' }}>
                {callToAction.text}
              </ButtonLink>
            )}
          </div>
        </div>
        {/* BOXES */}
        <div
          css={{
            maxWidth: 583,
            width: '100%',
            marginTop: 36,
            [presets.sm]: { marginTop: 50 },
            [presets.lg]: { marginTop: 0 },
          }}
        >
          {itemsLongText.map((el, index) => {
            return (
              <ItemBoxComp
                key={el.heading}
                isActive={activeIndex === index}
                showBorderBottom={activeIndex - index !== 1}
                onClick={() => {
                  setActiveIndex(index);
                }}
              >
                {activeIndex === index ? (
                  <>
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'space-between',
                        minHeight: 40,
                      }}
                    >
                      <StyledHeading>{el.heading}</StyledHeading>
                      <div css={{ display: 'flex', userSelect: 'none' }}>
                        <ArrowImage
                          src={ArrowIcon}
                          alt="Arrow"
                          isActive={activeIndex === index}
                        />
                      </div>
                    </div>
                    <div css={{ maxWidth: 400 }}>
                      <div
                        css={{
                          fontFamily: ['Open Sans']
                            .concat(SYSTEM_FONTS)
                            .join(', '),
                          fontSize: 14,
                          color: hexToRgba('#160B2C', 0.8),
                          marginTop: 8,
                          marginBottom: 0,

                          [presets.md]: { marginTop: 0 },
                        }}
                        dangerouslySetInnerHTML={{
                          __html: el.text.childMarkdownRemark.html,
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <StyledHeading>{el.heading}</StyledHeading>
                    <div css={{ display: 'flex', userSelect: 'none' }}>
                      <ArrowImage
                        src={ArrowIcon}
                        alt="Arrow"
                        isActive={activeIndex === index}
                      />
                    </div>
                  </>
                )}
              </ItemBoxComp>
            );
          })}
        </div>
        <div
          css={{
            display: 'block',
            marginTop: 50,
            [presets.lg]: { display: 'none', marginTop: 0 },
          }}
        >
          {callToAction && callToAction.isDisplayed && callToAction.url && (
            <ButtonLink to={callToAction.url} css={{ width: '100%' }}>
              {callToAction.text}
            </ButtonLink>
          )}
        </div>
      </Container>
    </section>
  );
};

export default Faq;
