import React from 'react';
import presets from '../../styles/presets';
import Container from '../Homepage/Container';
import { css } from '@emotion/core';
import { SYSTEM_FONTS } from '../../styles/typography';
import hexToRgba from 'hex-to-rgba';
import {
  ContentfulContentBlock,
  ContentfulImageHeadingAndShortText,
} from '../../graphql-types';
import { Row, Col } from 'emotion-flex';

interface ProblemsProps {
  problemData: ContentfulContentBlock;
  problemThirdNumber: string;
}

const BarItem = ({
  item,
  index,
}: {
  item: ContentfulImageHeadingAndShortText;
  index: number;
}) => {
  const { shortText } = item;
  const ratio = 469 / 100;

  return (
    <div css={{ marginBottom: 14 }}>
      <p
        css={{
          fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
          fontSize: 12,
          fontWeight: 500,
          marginBottom: 3,
          marginLeft: 6,
        }}
      >
        {item.prefix}
      </p>
      <div
        css={css`
          height: 44px;
          width: ${0.4 * ratio * Number(shortText)}px;
          background-color: ${index === 0 ? '#d2eedd' : '#9775C1'};
          border-radius: 24px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 26px;
          color: #fff;
          font-size: 22px;
          font-family: ${['Blacker Pro Display']
            .concat(SYSTEM_FONTS)
            .join(', ')};

          @media (min-width: 360px) {
            width: ${0.47 * ratio * Number(shortText)}px;
          }
          @media (min-width: 430px) {
            width: ${0.57 * ratio * Number(shortText)}px;
          }
          @media (min-width: 550px) {
            width: ${0.67 * ratio * Number(shortText)}px;
          }

          @media (min-width: 767px) {
            font-size: 28px;
            height: 48px;
            width: ${0.57 * ratio * Number(shortText)}px;
            padding-right: 37px;
          }

          @media (min-width: 850px) {
            width: ${0.7 * ratio * Number(shortText)}px;
          }

          @media (min-width: 1240px) {
            width: ${ratio * Number(shortText)}px;
          }
        `}
      >
        {item.heading}
      </div>
    </div>
  );
};

const ProblemAndImpact: React.FC<ProblemsProps> = ({
  problemData,
  problemThirdNumber,
}) => {
  return (
    <section>
      <div
        css={css`
          margin: 0 auto;
          padding-top: 146px;

          ${presets.md} {
            min-height: 400px;
          }
        `}
      >
        <Container css={css``}>
          <Row css={{ maxWidth: 1400, margin: '0 auto' }}>
            {/* 1 COL */}
            <Col
              xs={12}
              md={6}
              css={{
                marginBottom: 58,
                [presets.md]: { padding: '0 30px', marginBottom: 0 },
              }}
            >
              {problemData.items.map((item, index) => {
                return <BarItem key={item.id} {...{ item, index }} />;
              })}
            </Col>
            {/* 2 COL */}
            <Col
              xs={12}
              md={6}
              css={{
                [presets.md]: { padding: '36px 0 0 100px' },
              }}
            >
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  textAlign: 'left',
                }}
              >
                <p
                  css={{
                    fontFamily: ['Blacker Pro Display']
                      .concat(SYSTEM_FONTS)
                      .join(', '),
                    fontSize: 24,
                    lineHeight: '30px',
                    fontWeight: 500,
                    color: '#160b2c',
                    marginBottom: 18,
                    maxWidth: 450,

                    [presets.md]: {
                      fontSize: 36,
                      lineHeight: '40px',
                    },
                  }}
                >
                  {problemData.prefix}
                  <sup
                    css={{
                      fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
                      marginLeft: 5,
                      fontSize: 12,
                    }}
                  >
                    {problemThirdNumber}
                  </sup>
                </p>
                <p
                  css={{
                    fontFamily: ['Blacker Pro Display']
                      .concat(SYSTEM_FONTS)
                      .join(', '),
                    fontSize: 18,
                    color: '#160b2c',
                    marginBottom: 26,
                    maxWidth: 547,

                    [presets.lg]: {
                      fontSize: 20,
                    },
                  }}
                >
                  {problemData.title}
                </p>
                <p
                  css={{
                    fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
                    fontSize: 16,
                    color: hexToRgba('#160B2C', 0.8),
                    marginBottom: 0,
                    maxWidth: 500,

                    [presets.lg]: {
                      fontSize: 18,
                    },
                  }}
                >
                  {problemData.text.childMarkdownRemark.rawMarkdownBody}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default ProblemAndImpact;
